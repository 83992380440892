import { isSameDay, isWithinInterval } from 'date-fns';
import { toDate, utcToZonedTime } from 'date-fns-tz';

export default {
  methods: {
    dataPointExists (date, listOfDataPoints) {
      return listOfDataPoints.some(point => isSameDay(toDate(point.timestamp), toDate(date)));
    },
    dataPointsInRange (range, listOfDataPoints) {
      if (range.length === 2) {
        const dates = range.map((date) => utcToZonedTime(date)).sort((a, b) => a < b ? -1 : a > b ? 1 : 0);
        return listOfDataPoints.filter(record => {
          const timestamp = new Date(record.timestamp);
          const [ start, end ] = dates;
          timestamp.setHours(0, 0, 0, 0);
          return (isWithinInterval(timestamp, { start, end }));
        });
      }
      return [];
    },
    dataPointsInitialRange (listOfDataPoints) {
      if (listOfDataPoints.length) {
        const start = listOfDataPoints.length >= 5
          ? listOfDataPoints[4].timestamp
          : listOfDataPoints[listOfDataPoints.length - 1].timestamp;

        const end = listOfDataPoints[0].timestamp;

        const startTime = new Date(start);
        startTime.setUTCHours(0, 0, 0, 0);
        const endTime = new Date(end);
        endTime.setUTCHours(0, 0, 0, 0);

        return [ startTime, endTime ];
      }
    },
  },
};
