<template>
<v-container fluid pa-0>
  <v-layout row wrap v-if="moods.length > 0 && moodRange" gap-xs-3>
    <v-flex xs12 v-if="date">
      <v-layout row wrap justify-center align-center>
        <v-flex xs12 md10 lg8>
          <date-picker
                  :data="moods"
                  :dataByDate="moodRange"
                  :currDate="date"
                  :allowedDates="allowedDates"
                  v-on:setDate="setDate"/>
        </v-flex>
      </v-layout>
    </v-flex>

    <v-flex xs12>
      <v-layout row wrap justify-center align-center>
        <v-flex xs12 md10 lg8>
          <mood-chart :data="moodRange" :key="rangeKey"/>
        </v-flex>
      </v-layout>
    </v-flex>

    <v-flex xs12 v-if="!hideEntries">
      <v-layout row wrap v-for="mood in moodRange" :key="mood.id" gap-xs-3>
        <v-flex xs12>
          <mood-card :mood="mood" @edit="onEdit" @delete="onDelete"></mood-card>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>

  <v-layout row wrap v-else>
    <v-flex xs12>
      <h3 class="starling-h3 StarlingDarkGrey--text mb-3">{{$t('app.tools.mood_tracker.tabs.daily.empty.title')}}</h3>
      <p v-if="!hideEntries" class="starling-body primary--text">{{$t('app.tools.mood_tracker.tabs.daily.empty.subtitle')}}</p>
    </v-flex>
  </v-layout>
</v-container>
</template>

<script>
import { format } from 'date-fns';
import dataPointMixin from '@/scripts/mixins/dataPointMixin.js';
import datePicker from '@/views/components/utilities/date-picker.vue';
import moodChart from '@/views/tools/moods/components/moodHistory/mood-chart.vue';
import moodCard from '@/views/tools/moods/components/moodCard/mood-card.vue';

export default {
  name: 'mood-daily',
  components: {
    datePicker,
    moodChart,
    moodCard,
  },
  mixins: [ dataPointMixin ],
  props: {
    hideEntries: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      date: '',
      key: this.moods,
    };
  },
  computed: {
    moods() {
      return this.$store.getters['moods/moods'];
    },
    moodRange() {
      return this.$store.getters['moods/getMoodsByTimestamp'](this.date);
    },
    rangeKey() {
      return this.key;
    },
  },
  watch: {
    '$route.query.d'(d) {
      this.setQueryDate();
    },
    moods: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$log.debug('Moods changed', newValue);
        if (newValue && newValue.length) {
          this.setDate(format(new Date(newValue[0].timestamp), 'MM-dd-yy'));
        }
      },
    },
  },
  methods: {
    setDate(data) {
      this.date = data;
      this.key++;
    },
    allowedDates(val) {
      return this.dataPointExists(val, this.moods);
    },
    setQueryDate() {
      const hasMoods = this.$store.getters['moods/getMoodsByTimestamp'](this.$route.query.d);
      this.$log.debug(hasMoods);
      if (hasMoods.length && this.$route.query.d !== this.date) {
        this.date = this.$route.query.d;
        this.key++;
      }
      return hasMoods;
    },
    onEdit(mood) {
      this.$router.push({ name: 'mood-edit', params: { id: mood.id } });
    },
    onDelete(mood) {
      const successMessage = this.$t('app.tools.mood_tracker.notifications.deleted');
      this.$store.dispatch('moods/deleteMood', mood.id)
        .then(res => {
          this.$store.commit('setNotification', { text: successMessage });
          if (res) this.closeModal();
        });
    },
  },
};
</script>
