<template>
<div>
  <canvas ref="graph"></canvas>
  <div class="mt-3 text-center">
    <v-chip
            v-for="(item, index) in $t('app.tools.mood_tracker.moods')"
            :class="{[`${item.value} is-active`]: true, disabled: isDisabled[index], hidden: isHidden[index]}"
            :key="index"
            class="mx-1 selectable"
            disabled
            @click="(e) => toggleResult(e, index)">
      {{item.label}}
    </v-chip>
  </div>
</div>
</template>

<script>
import Chart from 'chart.js';
import * as moment from 'moment';

let dataLabels;

export default {
  name: 'moodChart',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    this.$log.debug('computing chart config');
    return {
      chartRef: false,
      chartData: {
        type: 'line',
        data: false,
        options: {
          responsive: true,
          lineTension: 1,
          borderDash: 0,
          tooltips: {
            callbacks: {
              label(x) {
                return dataLabels[x.datasetIndex].label + ': ' + x.yLabel;
              },
              title(x) {
                return '';
              },
            },
          },
          spanGaps: true,
          scales: {
            xAxes: [ {
              scaleLabel: {
                display: true,
                labelString: this.$t('app.tools.mood_tracker.tabs.daily.chart.label.xAxis'),
              },
              gridLines: {
                display: false,
              },
            } ],
            yAxes: [ {
              scaleLabel: {
                display: true,
                labelString: this.$t('app.tools.mood_tracker.tabs.daily.chart.label.yAxis'),
              },
              ticks: {
                min: 0,
                max: 10,
                padding: 2,
              },
              gridLines: {
                display: true,
              },
            } ],
          },
          legend: {
            display: false,
          },
        },
      },
      isDisabled: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
      },
      isHidden: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
      },
    };
  },
  mounted() {
    dataLabels = this.$t('app.tools.mood_tracker.moods');
    this.processData();
    Chart.defaults.global.defaultFontSize = 10;
    Chart.defaults.global.defaultFontFamily = 'var(--StarlingDefaultFont),Open Sans,Roboto,Noto Sans,-apple-system,BlinkMacSystemFont,sans-serif';
  },
  methods: {
    createChart(chartData) {
      const ctx = this.$refs.graph;
      /*eslint-disable*/
      let chart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options,
      });
      this.chartRef = chart;
      /* eslint-enable */
    },
    processData() {
      let data = this.data;
      data = data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

      const timeLabels = [];

      const moodData = {
        worried: [],
        sad: [],
        anxious: [],
        stressed: [],
        guilt: [],
        angry: [],
        relaxed: [],
        confident: [],
        happy: [],
      };

      data.forEach(item => {
        timeLabels.push(moment(item.timestamp).format('LT'));
        for (const result in moodData) {
          if (item.moodIntensities[result].selected) {
            moodData[result].push(item.moodIntensities[result].value);
          } else {
            moodData[result].push(null);
          }
        }
      });

      Object.keys(moodData).forEach((mood, index) => {
        const hasVal = moodData[mood].some((el) => {
          return el !== null;
        });

        if (!hasVal) {
          this.isHidden[index] = true;
        }
      });

      this.chartData.data = {
        labels: timeLabels,
        datasets: [
          {
            data: moodData.worried,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: '#0030e2',
            pointBackgroundColor: '#0030e2',
            pointRadius: 3,
            pointHoverRadius: 6,
          },
          {
            data: moodData.sad,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: '#7b2dbb',
            pointBackgroundColor: '#7b2dbb',
            pointRadius: 3,
            pointHoverRadius: 6,

          },
          {
            data: moodData.anxious,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: '#bf53b0',
            pointBackgroundColor: '#bf53b0',
            pointRadius: 3,
            pointHoverRadius: 6,

          },
          {
            data: moodData.stressed,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: '#bf306d',
            pointBackgroundColor: '#bf306d',
            pointRadius: 3,
            pointHoverRadius: 6,

          },
          {
            data: moodData.guilt,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: '#e5606a',
            pointBackgroundColor: '#e5606a',
            pointRadius: 3,
            pointHoverRadius: 6,

          },
          {
            data: moodData.angry,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: '#ec4110',
            pointBackgroundColor: '#ec4110',
            pointRadius: 3,
            pointHoverRadius: 6,

          },
          {
            data: moodData.relaxed,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: '#fb8938',
            pointBackgroundColor: '#fb8938',
            pointRadius: 3,
            pointHoverRadius: 6,

          },
          {
            data: moodData.confident,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: '#ffae3d',
            pointBackgroundColor: '#ffae3d',
            pointRadius: 3,
            pointHoverRadius: 6,

          },
          {
            data: moodData.happy,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: '#ffcf4a',
            pointBackgroundColor: '#ffcf4a',
            pointRadius: 3,
            pointHoverRadius: 6,
          },
        ],
      };
      this.createChart(this.chartData);
    },
    toggleResult(e, index) {
      const ci = this.chartRef;
      const meta = ci.getDatasetMeta(index);

      // See controller.isDatasetVisible comment
      meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;

      // We hid a dataset ... rerender the chart
      ci.update();
      this.isDisabled[index] = !this.isDisabled[index];
    },
  },
};
</script>
