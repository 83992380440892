var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "", "gap-xs-3": "" } },
    [
      _vm.moodHistory && _vm.moodHistory.length > 0
        ? [
            _vm.dateRange
              ? _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          row: "",
                          wrap: "",
                          "justify-center": "",
                          "align-center": ""
                        }
                      },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", md10: "", lg8: "" } },
                          [
                            _c("date-range", {
                              attrs: {
                                data: _vm.moodHistory,
                                dataInRange: _vm.historyRange,
                                dateRange: _vm.dateRange,
                                allowedDates: _vm.allowedDates
                              },
                              on: {
                                "update:data": function($event) {
                                  _vm.moodHistory = $event
                                },
                                "update:dataInRange": function($event) {
                                  _vm.historyRange = $event
                                },
                                "update:data-in-range": function($event) {
                                  _vm.historyRange = $event
                                },
                                "update:dateRange": function($event) {
                                  _vm.dateRange = $event
                                },
                                "update:date-range": function($event) {
                                  _vm.dateRange = $event
                                },
                                "update:allowedDates": function($event) {
                                  _vm.allowedDates = $event
                                },
                                "update:allowed-dates": function($event) {
                                  _vm.allowedDates = $event
                                },
                                setDateRange: _vm.setDateRange
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-flex",
              { attrs: { xs12: "" } },
              [
                _c(
                  "v-layout",
                  {
                    attrs: {
                      row: "",
                      wrap: "",
                      "justify-center": "",
                      "align-center": ""
                    }
                  },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", md10: "", lg8: "" } },
                      [
                        _c("mood-trend-chart", {
                          key: _vm.rangeKey,
                          attrs: { data: _vm.historyRange }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            !_vm.hideEntries
              ? _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          row: "",
                          wrap: "",
                          "justify-center": "",
                          "align-center": "",
                          "gap-xs-3": ""
                        }
                      },
                      _vm._l(_vm.historyRange, function(mood) {
                        return _c(
                          "v-flex",
                          { key: mood.id, attrs: { xs12: "" } },
                          [_c("mood-avg-card", { attrs: { mood: mood } })],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        : [
            _c("v-flex", { attrs: { xs12: "" } }, [
              _c("h3", { staticClass: "starling-h3 StarlingDarkGrey--text" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("app.tools.mood_tracker.tabs.history.empty.title")
                  )
                )
              ])
            ]),
            _c("v-flex", { attrs: { xs12: "" } }, [
              !_vm.hideEntries
                ? _c("p", { staticClass: "starling-body primary--text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "app.tools.mood_tracker.tabs.history.empty.subtitle"
                        )
                      )
                    )
                  ])
                : _vm._e()
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }