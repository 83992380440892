import { render, staticRenderFns } from "./date-picker.vue?vue&type=template&id=5b168269&"
import script from "./date-picker.vue?vue&type=script&lang=js&"
export * from "./date-picker.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VDatePicker,VDialog,VFlex,VIcon,VLayout,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/starling-app-dev-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5b168269')) {
      api.createRecord('5b168269', component.options)
    } else {
      api.reload('5b168269', component.options)
    }
    module.hot.accept("./date-picker.vue?vue&type=template&id=5b168269&", function () {
      api.rerender('5b168269', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/utilities/date-picker.vue"
export default component.exports