<template>
<v-layout align-center justify-center class="text-center">
  <v-flex xs2>
    <v-btn
      :disabled="prevDisabled"
      icon
      outline
      @click="navigateData('prev')">
      <v-icon>chevron_left</v-icon>
    </v-btn>
  </v-flex>
  <v-flex xs8 @click="dateModalOpen = true">
    <v-btn
      outline
      small>
      <v-icon small left>date_range</v-icon>
        {{datePickerLabel}}
      </v-btn>
  </v-flex>
  <v-flex xs2>
    <v-btn
      :disabled="nextDisabled"
      icon
      outline
      @click="navigateData('next')">
      <v-icon>chevron_right</v-icon>
    </v-btn>
  </v-flex>

  <v-dialog
    ref="dateDialog"
    v-model="dateModalOpen"
    :return-value.sync="date"
    persistent
    lazy
    full-width
    width="290px"
    content-class="radius-15">
    <v-date-picker
            color="primary"
      :locale="currentLanguage"
      v-model="date"
      :allowed-dates="allowedDates"
      :events="allowedDates"
      event-color="#3c65ff"
      scrollable>
      <v-spacer></v-spacer>
      <v-btn flat @click="dateModalOpen = false">{{$t('common.actions.cancel')}}</v-btn>
      <primary-button @click="selectDate(date)">{{$t('common.actions.save')}}</primary-button>
    </v-date-picker>
  </v-dialog>
</v-layout>
</template>

<script>
import { format, parseISO } from 'date-fns';
import * as moment from 'moment';
import PrimaryButton from '@/views/components/button/primary-button';

export default {
  name: 'datePicker',
  components: { PrimaryButton },
  props: {
    data: {
      type: Array,
      required: true,
    },
    dataByDate: {
      type: Array,
      required: true,
    },
    allowedDates: {
      type: Function,
      required: false,
    },
    currDate: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dateModalOpen: false,
      date: '',
      disableNext: true,
    };
  },
  computed: {
    datePickerLabel () {
      const date = this.currDate.replace(/-/g, '/');
      return moment(date).format('ll');
    },
    currentLanguage () {
      return this.$store.getters.currentLanguage;
    },
    datesArr() {
      const dates = this.data.reduce((a, b) => {
        const date = format(parseISO(b.timestamp), 'MM-dd-yy');
        if (!a.includes(date)) {
          a.push(date);
        }
        return a;
      }, []);
      return dates;
    },
    nextDisabled() {
      return this.currDate === this.datesArr[0] || this.disableNext;
    },
    prevDisabled() {
      return this.currDate === this.datesArr[this.datesArr.length - 1];
    },
  },
  created() {
    if (this.$route.query.d) this.disableNext = false;
  },
  methods: {
    navigateData(dir) {
      let date = this.date;
      const currDateIndex = this.datesArr.indexOf(this.currDate);

      if (dir === 'prev') {
        date = this.datesArr[currDateIndex + 1];
      }

      if (dir === 'next') {
        date = this.datesArr[currDateIndex - 1];
      }

      if (this.disableNext) this.disableNext = false;

      this.$emit('setDate', date);
    },
    selectDate(date) {
      if (date) {
        const formattedDate = format(parseISO(date), 'MM-dd-yy');
        this.date = formattedDate;
        this.$emit('setDate', formattedDate);
      }
      this.dateModalOpen = false;
    },
  },
};
</script>
