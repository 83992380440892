<!-- Render individual mood card entries history and it's details. -->

<template>
<v-layout row wrap gap-xs-3>
  <template v-if="moodHistory && moodHistory.length > 0">
    <v-flex xs12 v-if="dateRange">
      <v-layout row wrap justify-center align-center>
        <v-flex xs12 md10 lg8>
          <date-range
                  :data.sync="moodHistory"
                  :dataInRange.sync="historyRange"
                  :dateRange.sync="dateRange"
                  :allowedDates.sync="allowedDates"
                  v-on:setDateRange="setDateRange"/>
        </v-flex>
      </v-layout>
    </v-flex>

    <v-flex xs12>
      <v-layout row wrap justify-center align-center>
        <v-flex xs12 md10 lg8>
          <mood-trend-chart :data="historyRange" :key="rangeKey" />
        </v-flex>
      </v-layout>
    </v-flex>

    <v-flex xs12 v-if="!hideEntries">
      <v-layout row wrap justify-center align-center gap-xs-3>
        <v-flex xs12 v-for="mood in historyRange" :key="mood.id">
          <mood-avg-card :mood="mood"/>
        </v-flex>
      </v-layout>
    </v-flex>
  </template>

  <template v-else>
    <v-flex xs12>
      <h3 class="starling-h3 StarlingDarkGrey--text">{{$t('app.tools.mood_tracker.tabs.history.empty.title')}}</h3>
    </v-flex>
    <v-flex xs12>
      <p v-if="!hideEntries" class="starling-body primary--text">{{$t('app.tools.mood_tracker.tabs.history.empty.subtitle')}}</p>
    </v-flex>
  </template>
</v-layout>
</template>

<script>
import dataPointMixin from '@/scripts/mixins/dataPointMixin.js';
import dateRange from '@/views/components/utilities/date-range.vue';
import moodTrendChart from '@/views/tools/moods/components/moodHistory/mood-trend-chart.vue';
import moodAvgCard from '@/views/tools/moods/components/moodAverageCard/mood-avg-card.vue';

export default {
  name: 'mood-trends',
  components: {
    dateRange,
    moodTrendChart,
    moodAvgCard,
  },
  mixins: [ dataPointMixin ],
  props: {
    hideEntries: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dateRange: [],
      key: this.historyRange,
    };
  },
  computed: {
    moodHistory() {
      return this.$store.getters['moods/moodHistory'];
    },
    historyRange() {
      return this.dataPointsInRange(this.dateRange, this.moodHistory);
    },
    rangeKey() {
      return this.key;
    },
  },
  watch: {
    moodHistory: {
      immediate: true,
      handler(newValue) {
        this.$log.debug('Mood history changed', newValue);
        if (newValue && newValue.length) {
          this.setInitialDateRange();
          this.$forceUpdate();
          this.key++;
        }
      },
    },
  },
  methods: {
    allowedDates(val) {
      return this.dataPointExists(val, this.moodHistory);
    },
    setDateRange(data) {
      this.dateRange = data;
      this.key++;
    },
    setDate(data) {
      this.date = data;
      this.key++;
    },
    setInitialDateRange() {
      if (this.dateRange.length !== 2) {
        this.dateRange = this.dataPointsInitialRange(this.moodHistory);
      }
    },
  },
};
</script>
