<template>
<v-card class="mood-card">
  <v-card-title>
    <span class="starling-body font-weight-medium">{{formatDate(mood.timestamp || mood.date)}}</span>
    <v-spacer></v-spacer>
  </v-card-title>
  <v-divider light></v-divider>
  <mood-card-content
          :mood="mood"
          :isHome="isHome"
          class="scale-in-center">
  </mood-card-content>
</v-card>
</template>

<script>
import moodCardContent from '@/views/tools/moods/components/moodAverageCard/mood-avg-card-content.vue';
import * as moment from 'moment';

export default {
  name: 'moodAvgCard',
  components: {
    moodCardContent,
  },
  props: {
    mood: {
      type: Object,
      required: true,
    },
    isHome: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    formatDate(timestamp) {
      return moment(timestamp).format('LLLL').replace(moment(timestamp).format('LT'), '');
    },
  },
};
</script>

<style scoped>
.mood-card {
  border-radius: 15px;
}
</style>
