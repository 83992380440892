var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mood-card" },
    [
      _c(
        "v-card-title",
        [
          _c("span", { staticClass: "starling-body font-weight-medium" }, [
            _vm._v(_vm._s(_vm.formatDate(_vm.mood.timestamp || _vm.mood.date)))
          ]),
          _c("v-spacer")
        ],
        1
      ),
      _c("v-divider", { attrs: { light: "" } }),
      _c("mood-card-content", {
        staticClass: "scale-in-center",
        attrs: { mood: _vm.mood, isHome: _vm.isHome }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }