<template>
  <v-layout align-center justify-center class="text-center">
    <v-flex xs2>
      <v-btn
          :disabled="rangeData.length < 2 ||  (data[data.length - 1].timestamp === rangeData[0].timestamp)"
          icon
          outline
          @click="navigateData('prev')">
        <v-icon>chevron_left</v-icon>
      </v-btn>
    </v-flex>
    <v-flex xs8 @click="dateRangeModalOpen = true">
      <v-btn outline small>
        <v-icon small left>date_range</v-icon>
        {{ currentDateRange }}
      </v-btn>
    </v-flex>
    <v-flex xs2>
      <v-btn
          :disabled="rangeData.length < 2 || data[0].timestamp === rangeData[rangeData.length - 1].timestamp"
          icon
          outline
          @click="navigateData('next')">
        <v-icon>chevron_right</v-icon>
      </v-btn>
    </v-flex>

    <v-dialog
        v-if="data.length > 1"
        ref="dateRangeDialog"
        v-model="dateRangeModalOpen"
        :return-value.sync="dateRange"
        persistent
        lazy
        full-width
        width="290px"
        content-class="radius-15">
      <v-date-picker
          :title-date-format="formatDate"
          color="primary"
          :locale="currentLanguage"
          v-model="dateRange"
          @click:date="selectRange()"
          :allowed-dates="allowedDates"
          :events="allowedDates"
          event-color="#3c65ff"
          multiple
          scrollable>
        <v-spacer></v-spacer>
        <v-btn flat @click="dateRangeModalOpen = false">{{ $t('common.actions.cancel') }}</v-btn>
      </v-date-picker>
    </v-dialog>
  </v-layout>
</template>

<script>
import { isSameDay } from 'date-fns';
import * as moment from 'moment';

export default {
  name: 'dateRange',
  props: {
    data: {
      type: Array,
      required: true,
    },
    dataInRange: {
      type: Array,
      required: true,
    },
    allowedDates: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      dateRangeModalOpen: false,
      dateRange: [],
    };
  },
  computed: {
    currentDateRange() {
      if (this.rangeData.length === 0) return '';
      const startTimestamp = new Date(this.rangeData[0].timestamp);
      const endTimestamp = new Date(this.rangeData[this.rangeData.length - 1].timestamp);

      let dates = [ startTimestamp, endTimestamp ];
      dates.sort((a, b) => a - b);
      dates = dates.map(date => moment(date).format('ll'));
      return dates[0] !== dates[1] ? dates[0] + ' - ' + dates[1] : dates[0];
    },
    rangeData() {
      const data = this.dataInRange;
      data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      return data;
    },
    currentLanguage() {
      return this.$store.getters.currentLanguage;
    },
    datesArr() {
      const dates = this.data.reduce((a, b) => {
        if (!a.includes(b.timestamp)) {
          a.push(b.timestamp);
        }
        return a;
      }, []);
      return dates;
    },
  },
  watch: {
    'dateRangeModalOpen'(isOpen) {
      if (isOpen) {
        this.dateRange = [];
      }
    },
  },
  methods: {
    formatDate(dates) {
      if (!dates.length) {
        return this.$t('common.date_picker.none_selected');
      } else if (dates.length === 1) {
        return this.$t('common.date_picker.one_selected', { from: moment(dates[0]).format('ll') });
      } else if (dates.length === 2) {
        return this.$t('common.date_picker.two_selected', { from: moment(dates[0]).format('ll'), to: moment(dates[1]).format('ll') });
      }
      return '';
    },
    navigateData(dir) {
      const initialDateRange = [ this.rangeData[0].timestamp, this.rangeData[this.rangeData.length - 1].timestamp ];
      let range = initialDateRange;
      const startIndex = this.data.indexOf(this.rangeData[0]);
      const endIndex = this.data.indexOf(this.rangeData[this.rangeData.length - 1]);

      if (dir === 'prev') {
        if (startIndex + 5 < this.data.length) {
          range = [ this.data[startIndex + 5].timestamp, this.data[startIndex + 1].timestamp ];
        } else {
          range = [ this.data[this.data.length - 1].timestamp, this.data[startIndex - 1].timestamp ];
        }
      }

      if (dir === 'next') {
        if (endIndex - 5 <= 0) {
          range = [ this.data[endIndex].timestamp, this.data[0].timestamp ];
        } else {
          range = [ this.data[endIndex - 1].timestamp, this.data[endIndex - 5].timestamp ];
        }
      }

      if (initialDateRange[0] === initialDateRange[1]) {
        const currDate = this.datesArr.find((e) => {
          return isSameDay(new Date(e), new Date(initialDateRange[0]));
        });
        if (dir === 'prev') {
          const prevIndex = (this.datesArr.indexOf(currDate) + 1) >= this.datesArr.length - 1 ? this.datesArr.length - 1 : this.datesArr.indexOf(currDate) + 1;
          range = [ this.datesArr[prevIndex], this.data[endIndex - 1].timestamp ];
        } else {
          const nextIndex = (this.datesArr.indexOf(currDate) - 1) >= 0 ? this.datesArr.indexOf(currDate) - 1 : 0;
          range = [ this.data[startIndex + 1].timestamp, this.datesArr[nextIndex] ];
        }
      }

      this.$emit('setDateRange', range);
    },
    selectRange() {
      if (this.dateRange.length >= 2) {
        this.dateRangeModalOpen = false;
        const dates = [ new Date(this.dateRange[0]), new Date(this.dateRange[1]) ];
        this.$emit('setDateRange', dates);
      }
    },
  },
};
</script>
