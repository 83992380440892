<template>
<v-card-text class="mood-card-content pa-3">
  <v-layout row wrap>
    <v-flex
            xs12
            v-for="item in intensitiesArr"
            :key="item.label"
            :class="[item.label, 'is-active']">
      <p class="starling-body font-weight-bold primary--text mb-1">{{ $t(`app.tools.mood_tracker.tabs.tool.fields.emotions.${item.label}`) }}</p>
      <slider :value="item.intensity"
              :min="0"
              :max="10"
              :tick-labels="false"
              readonly>
      </slider>
      <v-layout no-wrap>
        <v-flex xs4 text-left><em class="font-weight-semi-bold">{{ $t('app.tools.mood_tracker.tabs.tool.fields.intensities.mild') }}</em></v-flex>
        <v-flex xs4 text-center><em class="font-weight-semi-bold">{{ $t('app.tools.mood_tracker.tabs.tool.fields.intensities.moderate') }}</em></v-flex>
        <v-flex xs4 text-right><em class="font-weight-semi-bold" style="margin-right:-5px">{{ $t('app.tools.mood_tracker.tabs.tool.fields.intensities.intense') }}</em></v-flex>
      </v-layout>
      <v-layout no-wrap>
        <v-flex xs4 text-left><span class="font-weight-semi-bold">0</span></v-flex>
        <v-flex xs4 text-right><span class="font-weight-semi-bold" style="margin-right:-5px">10</span></v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</v-card-text>
</template>

<script>
import { format } from 'date-fns';
import Slider from '../../../../components/slider';

export default {
  name: 'moodAverageCardContainer',
  components: { Slider },
  props: [ 'mood', 'isHome' ],
  computed: {
    actionPath() {
      return this.isHome ? '/moods' : ('/mood/' + (format(new Date(this.mood.timestamp), 'MM-dd-yy')));
    },
    intensitiesArr() {
      const orderedMoods = {
        worried: this.mood.moodIntensities['worried'],
        sad: this.mood.moodIntensities['sad'],
        anxious: this.mood.moodIntensities['anxious'],
        stressed: this.mood.moodIntensities['stressed'],
        guilt: this.mood.moodIntensities['guilt'],
        angry: this.mood.moodIntensities['angry'],
        relaxed: this.mood.moodIntensities['relaxed'],
        confident: this.mood.moodIntensities['confident'],
        happy: this.mood.moodIntensities['happy'],
      };
      const intensitiesArr = [];
      for (const key in orderedMoods) {
        if (orderedMoods[key] !== null && orderedMoods[key] !== undefined) {
          intensitiesArr.push({ label: key, intensity: (orderedMoods[key]) });
        }
      }
      return intensitiesArr;
    },
  },
};
</script>
